import Vue from 'vue'
import ElementUI from 'element-ui'; //引入element组件库
import 'element-ui/lib/theme-chalk/index.css'; //引入element样式文件
import './assets/css/flex.css';
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/font/iconfont.css' 
import 'libpag'
// import 'swiper/css/swiper.css'
Vue.config.productionTip = false
Vue.use(ElementUI); //使用element 

import axios from 'axios'
Vue.prototype.$http = axios;
//axios拦截器，发送请求之前操作
const on = Vue.prototype.$on
Vue.prototype.$on = function (event, func) {
  let timer
  let flag = true
  let newFunc = func
  if (event === 'click') {
    newFunc = function () {
      if (flag) {
        func.apply(this, arguments)
        flag = false
      }
      clearTimeout(timer)
      timer = setTimeout(function () {
        flag = true
      }, 500)
    }
  }
  on.call(this, event, newFunc)
}
// 进入路由之前
// router.beforeEach((to, from, next) => {
//   // to 将要进入的路由， from 将要离开的路由 ， next 让路由继续
//   // 获取当前打开的选项卡
//   store.commit('getTabs');
//   // 激活当前的选项卡
//   store.commit('setActiveTabs', to.name);
//   // let token = sessionStorage.getItem('token');
//   let token = localStorage.getItem('token');
//   if (to.path === '/login') {
//     if (token) {
//       next({ path: '/home' })
//     } else {
//       next();
//     }
//   } else {
//     if (!token && to.name !== 'login') {
//       next({ path: '/login' })
//     } else if (token && to.name == null) {
//       // 有token但是路径为空时
//       next({ path: '/home' });
//     } else if (token && to.name) {
//       next();
//     }
//   }
// })
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
